import ReportPieChartComponent from "../components/ReportPieChartComponent";
import ReplySatisfactionPoints from "../components/ReplySatisfactionPoints";
import Space from "tdesign-react/es/space/Space";
import {Divider, Loading} from "tdesign-react";
import BarChart from "../components/ReportBarChart";

const ReviewReport = ({taskId, commentList, PieData, pieDataLoading}) => {

    return (
        <Space direction="vertical" style={{width: '80vw'}}>
            <Loading loading={pieDataLoading} text="拼命加载中..." size="small">
                <ReportPieChartComponent commentList={commentList} taskId={taskId} PieDataList={PieData}/></Loading>
            <Divider/>
            <BarChart commentList={commentList} taskId={taskId}/>
            <Divider/>
            <ReplySatisfactionPoints taskId={taskId}/>
        </Space>
    );
}
export default ReviewReport;